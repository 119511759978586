import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import media from '../shared/media';

export type PageContainerProps = ContainerProps & {
  children: ReactNode;
}

export default ({ centered, top, children, narrow }: PageContainerProps) => (
  <Container narrow={narrow} centered={centered} top={top}>
    {children}
  </Container>
);

export interface ContainerProps {
  centered?: boolean;
  top?: boolean;
  narrow?: boolean;
}

const Container = styled.div<ContainerProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    padding: '30px 100px',

    [media('small')]: {
      padding: '30px 10px',
    },
  },
  ({ centered, top, narrow }) => ({
    alignItems: centered ? 'center' : 'auto',
    ...(top && { justifyContent: 'flex-start' }),
    ...(narrow && { padding: 0, width: '50%', margin: 'auto' }),
  }),
);
