"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("@chronos/constants");

var moment = require("moment");

var allowedColors = [constants_1.COLORS.BLUE, constants_1.COLORS.PURPLE, constants_1.COLORS.GRAY_DARK];

exports.getRandomInt = function () {
  var max = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var maxInt = Math.ceil(max);
  var minInt = Math.floor(min);
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt;
};

exports.getDate = function (date) {
  if (!date) {
    return new Date();
  }

  if (date instanceof Date) {
    return date;
  }

  if (typeof date === 'string' || typeof date === 'number') {
    return moment(date).toDate();
  }

  if (date.toDate) {
    return date.toDate();
  }

  return new Date();
};

exports.getMoment = function (date) {
  if (date === null || date === undefined) {
    return moment();
  }

  if (typeof date === 'string' || typeof date === 'number' || date instanceof Date) {
    return moment(date);
  }

  if (date.toDate) {
    return moment(date.toDate());
  }

  return moment();
};

exports.chooseRandomColor = function () {
  return allowedColors[exports.getRandomInt(2)];
};