"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLORS = Object.freeze({
  BLACK: '#030F09',
  BLUE: '#2817A6',
  CYAN_DARK: '#0097A6',
  CYAN: '#00C1D4',
  GRAY_DARK: '#69707F',
  GRAY_LIGHT: '#A8A8A8',
  GRAY_LIGHTER: '#EBEBEB',
  GRAY_LIGHTEST: '#FBFBFB',
  GRAY_BACKGROUND: '#F4F6FC',
  GRAY: '#8798AD',
  INDIGO_DARK: '#3F51B5',
  INDIGO_LIGHT: '#7986CB',
  INDIGO: '#5C6BC0',
  PINK_DARK: '#D81B60',
  PINK_LIGHT: '#EC407A',
  PINK: '#E91E63',
  PURPLE_LIGHT: '#B12598',
  PURPLE: '#6713D2',
  RED_LIGHT: '#ff6961',
  RED: '#EF5350',
  TEAL_DARK: '#00695C',
  TEAL_LIGHT: '#009688',
  TEAL: '#00796B',
  VIOLET_DARK: '#673AB7',
  VIOLET_LIGHT: '#9575CD',
  VIOLET: '#7E57C2',
  WHITE_OPAQUE: 'rgba(255, 255, 255, 0.4)',
  HOVER: 'rgba(0, 0, 0, 0.1)',
  WHITE: '#ffffff',
  TRANSPARENT: 'transparent'
});
exports.Shadows = {
  S1: function S1() {
    var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.COLORS.GRAY_LIGHT;
    return "0 6px 10px 0 ".concat(color);
  },
  S2: function S2() {
    var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.COLORS.GRAY_LIGHT;
    return "0 6px 16px 0 ".concat(color);
  },
  Popover: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)"
};
exports.PRIMARY_COLOR = exports.COLORS.PURPLE;
exports.SECONDARY_COLOR = exports.COLORS.CYAN;
exports.PROJECT_TYPE_COLORS = [exports.COLORS.CYAN, exports.COLORS.INDIGO, exports.COLORS.PINK, exports.COLORS.PURPLE, exports.COLORS.RED, exports.COLORS.TEAL, exports.COLORS.VIOLET];
exports.BorderRadius = 8;