"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getBlankProject = function (uid) {
  return {
    uid: uid,
    name: '',
    dueDate: new Date(),
    notes: '',
    billingType: '',
    amount: '',
    projectTypeId: ''
  };
};

exports.getBlankEntry = function (uid, projectId, type) {
  return {
    uid: uid,
    type: type,
    projectId: projectId,
    amount: '',
    description: '',
    category: '',
    entryDate: new Date(),
    billToClient: false
  };
};