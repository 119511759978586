import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { PRIMARY_COLOR } from '@chronos/constants';
import Logo from './Logo';
import Spacer from './Spacer';

export interface LadingProps {
}

const override = css`
  margin-right: 16px;
`;

export default (props: LadingProps) => (
  <Relative>
    <Logo />
    <Logo size={40} text />
    <Spacer />
    <PropagateLoader css={override} color={PRIMARY_COLOR} />
  </Relative>
);

const Relative = styled.div({
  height: '100vh',
  width: '100vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: 0.7,
});
