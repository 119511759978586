"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ENTRY_TYPES = Object.freeze({
  TIME: 'Time',
  EXPENSE: 'Expense',
  PAYMENT: 'Payment'
});
exports.BILLING_TYPES = {
  FIXED_FEE: 'Fixed Fee',
  TIME_MATERIALS: 'Time & Materials',
  NON_BILLABLE: 'Non-Billable'
};
exports.USER_TYPES = {
  ADMIN: 'Admin',
  PROJECT_MANAGER: 'Project Manager',
  TEAM_MEMBER: 'Team Member'
};
exports.SUBSCRIPTION_STATUS_TYPES = {
  SUBSCRIBED: 'Subscribed',
  TRIAL: 'Trial',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
  FREE: 'Free'
};
exports.DateFormat = {
  Regular: 'regular'
};
exports.DateType = {
  Day: 'day',
  Mini: 'mini'
};