"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("@chronos/constants");

exports.amountDisplay = function (type, amount) {
  switch (type) {
    case constants_1.ENTRY_TYPES.TIME:
      return typeof amount === 'number' ? "".concat(amount.toFixed(2), " hrs") : '--';

    case constants_1.ENTRY_TYPES.EXPENSE:
    case constants_1.ENTRY_TYPES.PAYMENT:
      return typeof amount === 'number' ? "$".concat(amount.toFixed(2)) : '--';

    default:
      return amount;
  }
};

exports.getEntriesAmountTotal = function (project, type) {
  var excludeBillToClient = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var amount = project.history ? project.history.reduce(function (total, entry) {
    var isType = entry.type === type;
    var allowBillToClient = excludeBillToClient || entry.billToClient;

    if (isType && allowBillToClient) {
      return total + Number(entry.amount) || 0;
    }

    return total;
  }, 0) : 0;
  return amount;
};

exports.getEntriesAmountTotalDisplay = function (project, type) {
  var excludeBillToClient = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return exports.amountDisplay(type, exports.getEntriesAmountTotal(project, type, excludeBillToClient));
};

exports.getTimeCost = function (project) {
  return exports.getEntriesAmountTotal(project, constants_1.ENTRY_TYPES.TIME, false) * (Number(project.amount) || 0);
};

exports.shouldDisplayClientOwes = function (project) {
  return project.billingType && project.billingType !== constants_1.BILLING_TYPES.NON_BILLABLE;
};

exports.getClientOwesAmount = function (project) {
  var amount = 0;

  if (project.billingType === constants_1.BILLING_TYPES.FIXED_FEE) {
    amount = (Number(project.amount) || 0) - exports.getEntriesAmountTotal(project, constants_1.ENTRY_TYPES.PAYMENT);
  } else if (project.billingType === constants_1.BILLING_TYPES.TIME_MATERIALS) {
    var timeAmount = exports.getTimeCost(project);
    var expenseAmount = exports.getEntriesAmountTotal(project, constants_1.ENTRY_TYPES.EXPENSE, false);
    var paymentAmount = exports.getEntriesAmountTotal(project, constants_1.ENTRY_TYPES.PAYMENT);
    amount = expenseAmount + timeAmount - paymentAmount;
  }

  return amount;
};

exports.getClientOwesAmountDisplay = function (project) {
  return exports.amountDisplay(constants_1.ENTRY_TYPES.PAYMENT, exports.getClientOwesAmount(project));
};