import React, { useState, MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import { Reference, Popper, Manager, PopperChildrenProps } from 'react-popper';
import styled from '@emotion/styled';

import { COLORS, Shadows } from '@chronos/constants';

import Modal from './Modal/Modal';
import Input, { InputProps } from './Input';
import Text from './Text';

export interface Option {
  label: string | number;
  value: any;
}

export type SelectProps<T extends object | string | number = Option> = Omit<InputProps, 'onSelect' | 'value'> & {
  options: T[];
  onSelect: (value: T) => void;
  valueProp?: keyof T;
  labelProp?: keyof T;
  searchable?: boolean;
  bvo?: boolean;
  value?: T | T[keyof T] | null;
  renderSlot?: () => JSX.Element;
  slotValue?: any;
  renderNoOptions?: () => JSX.Element;
}

function getInitialOption<T extends object | string | number = Option>(value: T | T[keyof T] | null, options: T[],
  {
    bvo,
    isSimpleArray,
    vp,
    lp,
  }: {
  bvo?: boolean;
  isSimpleArray: boolean;
  lp: keyof T;
  vp: keyof T;
}): Option | null {
  if (value) {
    if (isSimpleArray) {
      if (typeof value === 'string' || typeof value === 'number') {
        return {
          value,
          label: value,
        };
      }
    } else {
      // @ts-ignore
      const optionValue: any = bvo ? value : value[vp];
      const option = options.find((opt) => opt[vp] === optionValue);
      const newValue = option && option[vp];
      const label = option && option[lp];

      if (newValue && label) {
        return {
          value: newValue,
          // @ts-ignore
          label,
        };
      }
    }
  }

  return null;
}

export default function Select<T extends object | string | number = Option>({
  value = null,
  options,
  onSelect,
  valueProp,
  labelProp,
  searchable,
  renderSlot,
  bvo,
  slotValue,
  renderNoOptions,
  ...rest
}: SelectProps<T>) {
  const vp: keyof T = valueProp ?? 'value' as keyof T;
  const lp: keyof T = labelProp ?? 'label' as keyof T;
  const [isOpen, setIsOpenValue] = useState(false);
  const [query, setQuery] = useState('');
  const isSimpleArray = typeof options[0] === 'string' || typeof options[0] === 'number';
  const selectedOption = getInitialOption<T>(value, options, { bvo, vp, lp, isSimpleArray });

  const setIsOpen = (open: boolean) => {
    if (open !== isOpen) {
      if (open) setQuery('');
      setIsOpenValue(open);
    }
  };

  const handleSelect = (selectedValue: any | null): MouseEventHandler => (e) => {
    if (selectedValue ?? false) {
      if (bvo && !isSimpleArray) {
        onSelect(selectedValue[vp]);
      } else {
        onSelect(selectedValue);
      }
    }

    setIsOpen(false);
  };

  const isSelected = (option: any): boolean => {
    const optionValue = isSimpleArray ? option : option[vp];
    return selectedOption?.value === optionValue;
  };

  const filteredOptions = query === '' ? options : options.filter((option) => {
    const label: any = isSimpleArray ? option : option[lp];
    return label.toString().toLowerCase().includes(query.toLowerCase());
  });

  const renderMenuOption = (option: any) => {
    const label = isSimpleArray ? option : option[lp];
    const selected = isSelected(option);

    return (
      <MenuOption
        key={label}
        selected={selected}
        onClick={(e) => {
          handleSelect(option)(e);
        }}
      >
        <Text light={selected}>{label}</Text>
      </MenuOption>
    );
  };

  const renderOptions = () => {
    if (options.length === 0) {
      if (renderNoOptions) {
        return (
          <NoResults>
            { renderNoOptions() }
          </NoResults>
        );
      }

      return null;
    }
    return filteredOptions.length > 0 ? filteredOptions.map(renderMenuOption) : (
      <NoResults>
        <Text center>No results for <b>"{query}"</b></Text>
      </NoResults>
    );
  };

  const renderMenu = () => (
    <Menu>
      {renderOptions()}
      {
        renderSlot && (
          <MenuOption
            onClick={(e) => {
              handleSelect(slotValue ?? null)(e);
            }}
          >
            {renderSlot()}
          </MenuOption>
        )
      }
    </Menu>
  );

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TargetContent ref={ref}>
            {
              isOpen && searchable
                ? <Input autoComplete="off" {...rest} ref={undefined} value={query} onChange={({ target }) => setQuery(target.value)} dropdown />
                : <Input
                    autoComplete="off"
                    {...rest}
                    ref={undefined}
                    readOnly
                    onFocus={() => {
                      setIsOpen(true);
                    }}
                    value={selectedOption?.label || ''}
                    dropdown
                  />
            }
          </TargetContent>
        )}
      </Reference>

      {isOpen && <Modal heading="Category" onClose={() => {
        setIsOpen(false);
      }}>
        {renderMenu()}
      </Modal>}
    </Manager>
  );
}

const TargetContent = styled.div({
  cursor: 'pointer',
});

const PopperContent = styled.div({
  zIndex: 1000,
});

const Menu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '300px',
  marginTop: 5,
  paddingTop: 10,
  paddingBottom: 10,
  zIndex: 900,
});

interface MenuOptionProps {
  selected?: boolean;
}


const MenuOption = styled.div<MenuOptionProps>({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 20px',
  cursor: 'pointer',
  minHeight: 50,
},
({ selected }) => ({
  ...(selected ? {
    backgroundColor: COLORS.PURPLE,
    color: COLORS.WHITE,
  } : {
    ':hover': {
      backgroundColor: COLORS.HOVER,
    },
  }),
}));

const NoResults = styled.div({
  padding: 10,
  marginBottom: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
