import React, { ReactNode } from 'react';
import { PopperProps } from 'react-popper';
import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

import Text from './Text';
import Popover from './Popover';

export type PopoverMenuOption = MenuOptionProps & {
  label: string;
  value?: string | number;
  action?: () => void;
}

export interface PopoverMenuProps {
  children: ReactNode;
  options: PopoverMenuOption[];
  heading?: string;
  placement?: PopperProps['placement'];
  onSelect?: (value: string | number) => void;
}

export default function PopoverMenu({
  options,
  children,
  placement,
  heading,
  onSelect,
}: PopoverMenuProps) {
  return (
    <Popover padding={0} placement={placement} target={children}>
      {heading && <Text as="h2">{heading}</Text>}
      <Menu>
        {options.map(({ label, value, action, danger }) => (
          <MenuOption
            danger={danger}
            key={label}
            onClick={(e) => {
              e.nativeEvent.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (action) action();
              if (value !== undefined && onSelect) onSelect(value);
            }}
          >
            {label}
          </MenuOption>
        ))}
      </Menu>
    </Popover>
  );
}

const Menu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
  marginBottom: 10,
});

interface MenuOptionProps {
  danger?: boolean;
}

const MenuOption = styled.div<MenuOptionProps>({
  padding: '10px 20px',
  minWidth: 150,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: COLORS.HOVER,
  },
}, ({ danger }) => ({
  color: danger ? COLORS.RED : COLORS.GRAY_DARK,
}));
